<template>
    <div class="change-password-container">
        <h2>Change Your Password</h2>
        <form @submit.prevent="changePassword">
            <div class="form-group">
                <label for="newPassword">New Password</label>
                <input type="password" id="newPassword" v-model="newPassword" class="form-control" required />
            </div>
            <div class="form-group">
                <label for="confirmPassword">Confirm Password</label>
                <input type="password" id="confirmPassword" v-model="confirmPassword" class="form-control" required />
            </div>
            <button type="submit" class="btn btn-primary mt-3">Change Password</button>
        </form>
        <div v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</div>
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
    emits: ['passwordChanged'],
    setup(_, { emit }) {
        const newPassword = ref('');
        const confirmPassword = ref('');
        const errorMessage = ref('');
        const currentUser = AuthService.getCurrentUser();
        const router = useRouter(); // Access Vue Router for navigation

        const changePassword = async () => {
            if (newPassword.value !== confirmPassword.value) {
                errorMessage.value = 'Passwords do not match.';
                return;
            }

            try {
                await AuthService.changePassword(currentUser.token.email, newPassword.value);
                emit('passwordChanged'); // Notify the parent component
                AuthService.logout(); // Log the user out
                router.push('/login'); // Redirect to the login page
            } catch (error) {
                errorMessage.value = error.response?.data?.message || 'Failed to change password.';
            }
        };

        return {
            newPassword,
            confirmPassword,
            errorMessage,
            changePassword,
        };
    },
};
</script>

<style scoped>
.change-password-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}
</style>
