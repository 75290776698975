<template>
    <div v-if="isLoggedIn">
      <nav class="navbar navbar-expand-lg navbar-dark gradient-custom" :style="navbarStyle">
        <div class="container-fluid">
          <router-link class="navbar-brand" to="/">Home</router-link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <!-- Title in the Center -->
          <div class="navbar-title">
            {{ titleStore.text }}
          </div>

          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Admin
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><router-link class="dropdown-item" to="/import">Import</router-link></li>
                  <li><router-link class="dropdown-item" to="/eventsview">Anlässe</router-link></li>
                  <li><router-link class="dropdown-item" to="/grouporg">Gruppen</router-link></li>
                  <li><router-link class="dropdown-item" to="/admin/contexts">Kontext</router-link></li>
                  <li><router-link class="dropdown-item" to="/admin/usermanager">Benutzer</router-link></li>
                  <li><router-link class="dropdown-item" to="/admin/mutations">Mutationen</router-link></li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Hi {{ userName }}
                </a>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <li><router-link class="dropdown-item" to="/logout">Logout</router-link></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
</template>

<script>
import { ref, computed, inject } from 'vue';
import AuthService from '@/services/AuthService';


export default {

  setup() {
    const isLoggedIn = ref(AuthService.getLoginStatus());
    const token = computed(() => AuthService.getCurrentUser());

    const userName = computed(() => token.value?.token?.name || 'User');



    // Inject the title store
    const titleStore = inject('titleStore');

    // Compute the gradient style
    const navbarStyle = computed(() => ({
      background: `linear-gradient(45deg, ${titleStore.gradient[0]}, ${titleStore.gradient[1]})`,
      color: titleStore.foregroundColor, // Apply foreground color globally
    }));

    return {
      isLoggedIn,
      userName,
      titleStore,
      navbarStyle,
    };
  },
};
</script>


<style scoped>
.gradient-custom {
  background: linear-gradient(45deg, #750b0b, #ad565a);
}

.navbar {
  z-index: 10;
}

.navbar-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.25rem;
  font-weight: bold;
}

.navbar {
  z-index: 10;
}

.navbar a,
.navbar .router-link-active,
.navbar .router-link-exact-active {
  color: inherit !important;
  /* Ensure color inherits from the navbar */
  text-decoration: none;
  /* Optional: Ensure links don't have underlines */
}

.navbar a:hover,
.navbar .router-link-active:hover,
.navbar .router-link-exact-active:hover {
  color: inherit !important;
  /* Prevent Bootstrap hover styles from overriding */
}

.navbar .dropdown-menu .dropdown-item {
  color: inherit !important;
  /* Ensure dropdown items inherit the navbar color */
}

.navbar .dropdown-menu .dropdown-item:hover,
.navbar .dropdown-menu .dropdown-item:focus {
  color: inherit !important;
}
</style>