import { reactive } from 'vue';

const toasts = reactive([]);

const addToast = (message, type = 'info', duration = 5000) => {
    const id = Date.now(); // Unique ID for each toast
    toasts.push({ id, message, type });

    // Automatically remove toast after a certain time
    setTimeout(() => removeToast(id), duration);
};

const removeToast = (id) => {
    const index = toasts.findIndex(toast => toast.id === id);
    console.log("removing toasts");
    if (index !== -1) {
        toasts.splice(index, 1);
    }
};

export const useToastStore = () => {
    return { toasts, addToast, removeToast };
};