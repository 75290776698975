import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { API_URL } from '@/globals';

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Helper function to decode JWT and check expiration
function isTokenExpired(token) {
  const decoded = jwtDecode(token);
  const now = Date.now().valueOf() / 1000;
  return decoded.exp < now;
}

function logout() {
  // Clear tokens from localStorage
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');

  // Redirect to login page (assuming you have a route for login)
  window.location.href = '/login';
}

// Refresh token function
async function refreshToken() {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post(API_URL + 'auth/refresh-token', {
      token: localStorage.getItem('accessToken'),
      refreshToken: refreshToken
    });

    // Update tokens in storage
    localStorage.setItem('accessToken', response.data.token);
    localStorage.setItem('refreshToken', response.data.refreshToken);

    return response.data.token;
  } catch (error) {
    console.error('Refresh token failed:', error);
    // Auto logout if refresh token fails
    logout();
    return null;
  }
}

// Variables to handle refresh token queue
let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed(token) {
  refreshSubscribers.map(callback => callback(token));
}

function subscribeTokenRefresh(callback) {
  refreshSubscribers.push(callback);
}

// Axios request interceptor to handle token refresh
apiClient.interceptors.request.use(async config => {
  let token = localStorage.getItem('accessToken');
  if (token && isTokenExpired(token)) {
    if (!isRefreshing) {
      isRefreshing = true;
      token = await refreshToken();
      isRefreshing = false;
      onRefreshed(token);
    }

    await new Promise(resolve => {
      subscribeTokenRefresh(token => {
        config.headers.Authorization = `Bearer ${token}`;
        resolve();
      });
    });
  } else if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default apiClient;
