<template>

    <div class="tost-container">
        <div v-for="toast in toastStore.toasts" :key="toast.id" class="tost" :class="toast.type"
            @click="toastStore.removeToast(toast.id)">
            <span>{{ toast.message }}</span>
        </div>
    </div>


</template>

<script>
import { inject } from 'vue';

export default {
    name: 'ToastView',
    setup() {
        const toastStore = inject('toastStore');
        console.log("ToastView loaded", toastStore);

        if (!toastStore) {
            console.error('Toast store not found!');
        }

        return { toastStore };
    },
};
</script>


<style scoped>
.tost-container {
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
}

.tost {
    display: flex;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    color: white;
    cursor: pointer;
    /* Ensures the pointer icon shows on hover */
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.tost.info {
    background-color: #2196f3;
}

.tost.success {
    background-color: #4caf50;
}

.tost.warning {
    background-color: #ff9800;
}

.tost.error {
    background-color: #f44336;
}

.tost-container>.tost:hover {
    opacity: 0.9;
    /* Add visual feedback on hover */
}
</style>
