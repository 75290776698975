import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faSpinner,
    faHouse,
    faRightFromBracket,
    faFilePdf,
    faGear,
    faSave,
    faWrench,
    faHeartbeat,
    faSoap,
    faCogs,
    faCar,
    faPhone,
    faRadiation,
    faUtensils,
    faCarrot,
    faDesktop,
    faUserNurse,
    faWalkieTalkie,
    faBox,
    faTree,
    faBullhorn,
    faPen
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faSpinner,
    faHouse,
    faRightFromBracket,
    faFilePdf,
    faGear,
    faSave,
    faWrench,
    faHeartbeat,
    faSoap,
    faCogs,
    faCar,
    faPhone,
    faRadiation,
    faUtensils,
    faCarrot,
    faDesktop,
    faUserNurse,
    faWalkieTalkie,
    faBox,
    faTree,
    faBullhorn,
    faPen);


