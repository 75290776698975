import { reactive } from 'vue';

export const titleStore = reactive({
    text: '',
    gradient: ['#750b0b', '#ad565a'], // Default gradient colors
    foregroundColor: '#ffffff', // Default foreground (text) color

    /**
     * Sets the gradient and computes the foreground color based on a base color.
     * @param {string} baseColor - The base color in hex format.
     */
    setGradientFromColor(baseColor) {
        const lighterColor = lightenColor(baseColor, 40); // Lighten by 40%
        this.gradient = [baseColor, lighterColor];
        this.foregroundColor = getContrastColor(baseColor);
    },

    /**
     * Sets the title and optionally updates the gradient based on a base color.
     * @param {string} newTitle - The new title to display.
     * @param {string} baseColor - Optional base color to generate a gradient.
     */
    setTitle(newTitle, baseColor) {
        this.text = newTitle;
        if (baseColor) {
            this.setGradientFromColor(baseColor);
        }
    },

    /**
     * Resets the title, gradient, and foreground color to their default values.
     */
    reset() {
        this.text = '';
        this.gradient = ['#750b0b', '#ad565a']; // Default gradient
        this.foregroundColor = '#ffffff'; // Default text color
    },
});

/**
 * Lightens a color by a given percentage.
 * @param {string} color - The hex color (e.g., "#ff0000").
 * @param {number} percent - The percentage to lighten the color.
 * @returns {string} - The new hex color.
 */
function lightenColor(color, percent) {
    const num = parseInt(color.replace('#', ''), 16);
    const r = Math.min(255, Math.floor((num >> 16) * (1 + percent / 100)));
    const g = Math.min(255, Math.floor(((num >> 8) & 0x00ff) * (1 + percent / 100)));
    const b = Math.min(255, Math.floor((num & 0x0000ff) * (1 + percent / 100)));
    return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
}

/**
 * Determines the appropriate contrast color (black or white) based on luminance.
 * @param {string} hexColor - The hex color (e.g., "#ff0000").
 * @returns {string} - "#ffffff" for dark background, "#000000" for light background.
 */
function getContrastColor(hexColor) {
    const color = hexColor.replace('#', '');
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminance > 0.5 ? '#000000' : '#ffffff';
}
