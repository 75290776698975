import { createRouter, createWebHistory } from 'vue-router'
import AuthService from '@/services/AuthService';
//import component from 'vue3-table-lite/ts';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/UserLogin.vue'),
    meta: { authRequired: false }
  },
  {
    path: '/logout',
    name: 'userLogout',
    component: () => import('../components/UserLogout.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/',
    name: 'homeview',
    component: () => import('../views/HomeView.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/import',
    name: 'Data import',
    component: () => import('../views/ImportView.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/organization/:id/:root_group_id/:oname/:cmode?',
    name: 'OrganizationChart',
    component: () => import('../views/OrganizationView.vue'),
    props: true,
    meta: { authRequired: true }
  },
  {
    path: '/adzs/:id/:modeid?',
    name: 'AdZSView',
    component: () => import('../views/AdZSView.vue'),
    props: true,
    meta: { authRequired: true }
  },
  {
    path: '/timetable/:id',
    name: 'TimeTable',
    component: () => import('../views/TimeTable.vue'),
    meta: { authRequired: true },
    props: true
  },
  {
    path: '/timetableview/:id',
    name: 'TimeTableView',
    component: () => import('../views/TimeTableView.vue'),
    meta: { authRequired: true },
    props: true
  },
  {
    path: '/people',
    name: 'Organization Members',
    component: () => import('../views/PeopleView.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/grouporg',
    name: 'Group Organization',
    component: () => import('../views/group/GroupOrganization.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: () => import('../views/EventView.vue'),
    props: true,
    meta: { authRequired: true }
  },
  {
    path: '/context/:id',
    name: 'Context',
    component: () => import('../views/ContextView.vue'),
    props: true,
    meta: { authRequired: true }
  },
  {
    path: '/eventusers/:id',
    name: 'EventUsers',
    component: () => import('../components/EventUsers.vue'),
    props: true,
    meta: { authRequired: true }
  },
  {
    path: '/eventsview',
    name: 'EventView',
    component: () => import('../views/EventsView.vue'),
    meta: { authRequired: true }
  },
  {
    path: '/lessons',
    name: 'Lessons',
    component: () => import('../views/LessonList'),

  },
  {
    path: '/lessons/create',
    component: () => import('../components/MarkdownEditor'),
    props: { isEditing: false },
    name: 'CreateLesson'

  },
  {
    path: '/lessons/edit/:id',
    component: () => import('../components/MarkdownEditor'),
    name: 'EditLesson',
    props: { isEditing: true },

  },
  {
    path: '/admin/contexts',
    component: () => import('../views/admin/ContextManager'),
    name: 'Contexts',
  },
  {
    path: '/admin/usermanager',
    component: () => import('../views/admin/UserManager'),
    name: 'UserManager',
  },
  {
    path: '/admin/mutations',
    component: () => import('../views/admin/ChangedUsers'),
    name: 'Mutations',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = AuthService.getLoginStatus().value;

  if (to.meta.authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});


export default router;
