import { ref, reactive } from 'vue';
import apiClient from './apiClient';
import { jwtDecode } from "jwt-decode";

const isLoggedIn = ref(false);  // Reactive variable to track login status
const authState = reactive({ lastEvent: null, token: null }); // Reactive state for login/logout events


class AuthService {
  constructor() {
    this.initializeLoginStatus();
  }

  initializeLoginStatus() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      isLoggedIn.value = true;
    } else {
      isLoggedIn.value = false;
    }
  }

  login(user) {
    return apiClient
      .post('auth/login', {
        email: user.EMail,
        password: user.Password,
      })
      .then((response) => {
        if (response.data.token && response.data.refreshToken) {
          localStorage.setItem('accessToken', response.data.token);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          isLoggedIn.value = true;  // Set logged in status to true
          authState.lastEvent = 'login';
          authState.token = response.data.token; // Update token in authState
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    isLoggedIn.value = false;  // Set logged in status to false
    authState.lastEvent = 'logout'; // Trigger reactive change
  }

  register(user) {
    return apiClient.post('auth/register', {
      email: user.EMail,
      password: user.Password,
    });
  }

  async refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      try {
        const response = await apiClient.post('auth/refresh-token', {
          token: localStorage.getItem('accessToken'),
          refreshToken: refreshToken,
        });
        if (response.data.token) {
          localStorage.setItem('accessToken', response.data.token);
          if (response.data.refreshToken) {
            localStorage.setItem('refreshToken', response.data.refreshToken);
          }
          return response.data.token;
        }
      } catch (error) {
        console.error('Token refresh failed', error);
        this.logout();  // Log out if refresh fails
      }
    }
    return null;
  }

  async changePassword(email, newPassword) {
    const token = this.getCurrentUser()?.token;
    if (!token) throw new Error('User is not logged in.');

    return await apiClient.post('auth/update-password', {
      email: email,
      password: newPassword,
    }, {
      token: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
    });
  }

  getCurrentUser() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      const decoded = jwtDecode(token);
      const now = Date.now() / 1000;
      if (decoded.exp < now) {
        this.logout(); // Token expired
        return null;
      }

      // Convert firstlogin to a boolean
      if (decoded.firstlogin !== undefined) {
        decoded.firstlogin = decoded.firstlogin === "True" || decoded.firstlogin === true;
      }

      isLoggedIn.value = true;
      return { token: decoded, refreshToken: localStorage.getItem('refreshToken') };
    }
    isLoggedIn.value = false;
    return null;
  }


  getLoginStatus() {
    return isLoggedIn;
  }

  getAuthState() {
    return authState; // Expose reactive auth state
  }
}

export default new AuthService();
