<template>
  <div id="app">
    <ToastView />
    <MenuBar v-if="!isFirstLogin" />
    <ChangePassword v-else @passwordChanged="handlePasswordChanged" />
    <router-view v-if="!isFirstLogin" />
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from './services/AuthService';
import MenuBar from './views/MenuBar.vue';
import ToastView from './components/ToastView.vue';
import ChangePassword from './components/ChangePassword.vue';

export default {
  name: "App",
  components: {
    MenuBar,
    ToastView,
    ChangePassword,
  },
  setup() {
    const isFirstLogin = ref(false);
    const router = useRouter();
    const authState = AuthService.getAuthState();

    const checkFirstLogin = () => {
      const currentUser = AuthService.getCurrentUser();
      if (currentUser && currentUser.token.firstlogin === true) {
        isFirstLogin.value = true;
      } else {
        isFirstLogin.value = false;
      }
    };

    const handlePasswordChanged = () => {
      isFirstLogin.value = false;
      AuthService.logout();
      router.push('/login'); // Redirect to login after password change
    };

    onMounted(() => {
      checkFirstLogin();
    });

    // Watch for changes in authState and react accordingly
    watch(
      () => authState.lastEvent,
      (newEvent) => {
        if (newEvent === 'login') {
          checkFirstLogin();
        }
      }
    );

    return {
      isFirstLogin,
      handlePasswordChanged,
    };
  },
};
</script>
