// Core Vue imports
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';

// Third-party libraries
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';
import deDE from '@kangc/v-md-editor/lib/lang/de-DE';

import { useToastStore } from './toastStore';
import { titleStore } from './titleStore';

// Custom files
import './fontawesome';

// Configure VueMarkdownEditor
VueMarkdownEditor.lang.use('de-DE', deDE);
VueMarkdownEditor.use(vuepressTheme, { Prism });

// Create the app
const app = createApp(App);
app.provide('toastStore', useToastStore());
app.provide('titleStore', titleStore);
app.use(router);
app.use(VueMarkdownEditor);
app.use(createPinia());
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
